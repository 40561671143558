import { render, staticRenderFns } from "./Transaction.vue?vue&type=template&id=118f1225&scoped=true"
import script from "./Transaction.vue?vue&type=script&lang=js"
export * from "./Transaction.vue?vue&type=script&lang=js"
import style0 from "./Transaction.vue?vue&type=style&index=0&id=118f1225&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__cac_qoazctsovguzye32rwzarzod4q/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "118f1225",
  null
  
)

export default component.exports